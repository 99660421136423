<template>
  <div class="relative flex">
    <input
      class="focus:outline-none block w-full cursor-pointer rounded-l-md border py-1 px-2 text-slate-500 shadow-sm"
      :class="
        invalid
          ? 'border-2 border-rose-600 focus:border-rose-500 focus:ring focus:ring-rose-200'
          : 'border border-slate-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200'
      "
      :id="id"
      readonly
      :value="getLocaleDate(locale, modelValue)"
      @click="toggleCalendar()"
    />
    <button
      class="rounded-r-md px-2 py-1 font-semibold"
      :class="`bg-${variant}-500 disabled:bg-${variant}-500 hover:bg-${variant}-600 focus:outline-none focus:ring focus:ring-${variant}-300 text-${textVariant}`"
      type="button"
      @click="toggleCalendar()"
    >
      <CalendarIcon class="h-5 w-5" />
    </button>
    <div
      v-if="showCalendar"
      class="absolute top-full left-1/2 z-20 w-full min-w-full -translate-x-1/2 transform rounded-md border border-slate-300 bg-white sm:w-96"
    >
      <div class="grid grid-cols-3 border-b border-slate-300 py-2">
        <div class="flex items-center justify-center gap-2">
          <button
            class="rounded-md px-4 py-2 font-semibold"
            :class="`bg-${variant}-500 disabled:bg-${variant}-500 hover:bg-${variant}-600 focus:outline-none focus:ring focus:ring-${variant}-300 text-${textVariant}`"
            type="button"
            @click="previousYear()"
          >
            <ChevronDoubleLeftIcon class="h-5 w-5" />
          </button>
          <button
            class="rounded-md px-4 py-2 font-semibold"
            :class="`bg-${variant}-500 disabled:bg-${variant}-500 hover:bg-${variant}-600 focus:outline-none focus:ring focus:ring-${variant}-300 text-${textVariant}`"
            type="button"
            @click="previousMonth()"
          >
            <ChevronLeftIcon class="h-5 w-5" />
          </button>
        </div>
        <div class="flex items-center justify-center">
          <span class="text-lg font-semibold"
            >{{ t(`months.${month}`) }} {{ year }}</span
          >
        </div>
        <div class="flex items-center justify-center gap-2">
          <button
            class="rounded-md px-4 py-2 font-semibold"
            :class="`bg-${variant}-500 disabled:bg-${variant}-500 hover:bg-${variant}-600 focus:outline-none focus:ring focus:ring-${variant}-300 text-${textVariant}`"
            type="button"
            @click="nextMonth()"
          >
            <ChevronRightIcon class="h-5 w-5" />
          </button>
          <button
            class="rounded-md px-4 py-2 font-semibold"
            :class="`bg-${variant}-500 disabled:bg-${variant}-500 hover:bg-${variant}-600 focus:outline-none focus:ring focus:ring-${variant}-300 text-${textVariant}`"
            type="button"
            @click="nextYear()"
          >
            <ChevronDoubleRightIcon class="h-5 w-5" />
          </button>
        </div>
      </div>
      <div class="m-2 grid grid-cols-7 gap-1">
        <div
          v-for="day in 7"
          :key="day"
          class="text-md flex items-center justify-center font-light"
        >
          {{ t(`days.${day == 7 ? 0 : day - 1 + startDay}`) }}
        </div>
        <div
          v-for="empty in firstWeekDate == 0 ? 6 : firstWeekDate - 1"
          :key="empty"
          class="invisible"
        >
          {{ empty }}
        </div>
        <div
          v-for="day in lastDate"
          :key="day"
          class="flex items-center justify-center"
        >
          <div
            class="text-md aspect-square flex w-12 cursor-pointer items-center justify-center rounded-full"
            :class="
              isCurrentDate(day)
                ? `bg-${variant}-500 text-${textVariant} focus:outline-none focus:ring focus:ring-${variant}-300`
                : `hover:bg-${variant}-600 focus:outline-none focus:ring focus:ring-${variant}-300 hover:text-${textVariant}`
            "
            @click="selectDate(day)"
          >
            {{ day }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CalendarIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/vue/24/outline";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  getISODate,
  getLocaleDate,
  getMonth,
  getYear,
} from "../../utils/dateUtils";

export default {
  components: {
    CalendarIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: {
    id: {
      required: false,
      type: String,
    },
    invalid: {
      default: false,
      required: false,
      type: Boolean,
    },
    modelValue: {
      required: true,
      type: String,
    },
    startDay: {
      default: 1,
      required: false,
      type: Number,
    },
    textVariant: {
      required: true,
      type: String,
      validator(value) {
        return ["black", "white"].includes(value);
      },
    },
    variant: {
      required: true,
      type: String,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { locale, t } = useI18n();

    const month = ref(getMonth(props.modelValue));
    const year = ref(getYear(props.modelValue));

    const firstDate = computed(
      () => new Date(`${year.value}-${month.value + 1}-1`)
    );
    const lastDate = computed(() => {
      const date = new Date(
        `${year.value}-${month.value == 11 ? 1 : month.value + 2}-1`
      );
      date.setDate(0);
      return date.getDate();
    });
    const firstWeekDate = computed(() => firstDate.value.getDay());

    const showCalendar = ref(false);

    const toggleCalendar = () => {
      showCalendar.value = !showCalendar.value;
    };

    const previousMonth = () => {
      if (month.value > 0) {
        month.value--;
      } else {
        month.value = 11;
        year.value--;
      }
    };

    const nextMonth = () => {
      if (month.value < 11) {
        month.value++;
      } else {
        month.value = 0;
        year.value++;
      }
    };

    const previousYear = () => {
      year.value--;
    };

    const nextYear = () => {
      year.value++;
    };

    const selectDate = (day) => {
      const date = new Date(`${year.value}-${month.value + 1}-${day}`);
      emit("update:modelValue", getISODate(date));
      toggleCalendar();
    };

    const isCurrentDate = (day) => {
      const date = new Date(`${year.value}-${month.value + 1}-${day}`);
      return getISODate(date) == props.modelValue;
    };

    return {
      emit,
      firstDate,
      firstWeekDate,
      getLocaleDate,
      isCurrentDate,
      lastDate,
      locale,
      month,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear,
      selectDate,
      showCalendar,
      t,
      toggleCalendar,
      year,
    };
  },
};
</script>
