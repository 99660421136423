const validators = {
  isEmail: async (value) => {
    return /^\S+@\S+\.\S+$/.test(value);
  },

  isMatch: async (value, comparable) => {
    return value === comparable;
  },

  isPhoneNumber: async (value) => {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
      value
    );
  },

  isPostalCode: async (value) => {
    return /^[0-9]{5}$/.test(value);
  },

  isRequired: async (value) => {
    return value && value.length;
  },
};

const validate = async (rules, value, match) => {
  for (const rule of rules) {
    let valid = true;
    if (rule == "isMatch") {
      valid = await validators[rule](value, match);
    } else {
      valid = await validators[rule](value);
    }
    if (!valid) return rule;
  }
};

export default validate;
